var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/home' }}},[_vm._v("主页")]),_c('el-breadcrumb-item',[_vm._v("审批设置")]),_c('el-breadcrumb-item',[_vm._v("审批流程")])],1),_c('el-card',[_c('el-row',{attrs:{"gutter":30}},[_c('el-form',{ref:"queryinfoRef",attrs:{"model":_vm.queryinfo}},[_c('el-col',{attrs:{"span":6.5}},[_c('el-form-item',{attrs:{"prop":"name"}},[_c('div',{staticClass:"divSpan",staticStyle:{"display":"inline-block"}},[_vm._v(" 流程名称： ")]),_c('div',{staticStyle:{"display":"inline-block"}},[_c('el-input',{attrs:{"placeholder":"请输入搜索的名称","clearable":""},on:{"clear":_vm.getList},model:{value:(_vm.queryinfo.name),callback:function ($$v) {_vm.$set(_vm.queryinfo, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"queryinfo.name"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":_vm.seachChange},slot:"append"})],1)],1)])],1)],1),_c('el-col',{attrs:{"span":2}},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-circle-plus"},on:{"click":_vm.AddModel}},[_vm._v("新增审批流程")])],1)],1),_c('el-row',{staticStyle:{"display":"flex"},attrs:{"gutter":30}},[_c('el-table',{attrs:{"data":_vm.ApprovalModelList,"header-cell-style":{
          textAlign: 'center',
          background: '#FAFAFA',
          color: 'rgba(0, 0, 0, 0.85)',
          fontWeight: '500',
        },"cell-style":{
          textAlign: 'center',
        },"tooltip-effect":"dark"}},[_c('el-table-column',{attrs:{"type":"index","min-width":"50px"}}),_c('el-table-column',{attrs:{"prop":"type","label":"流程类型","min-width":"100px"}}),_c('el-table-column',{attrs:{"prop":"name","label":"流程名称","min-width":"150px"}}),_c('el-table-column',{attrs:{"prop":"note","label":"描述","min-width":"200px","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"createTime","label":"创建时间","min-width":"150px"}}),_c('el-table-column',{attrs:{"label":"操作","min-width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.ModelSet(scope.row)}}},[_vm._v("设置")])]}}])})],1)],1),_c('el-pagination',{attrs:{"background":"","current-page":_vm.queryinfo.pageNum,"page-size":_vm.queryinfo.pageSize,"layout":"total, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }