<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>审批设置</el-breadcrumb-item>
      <el-breadcrumb-item>审批流程</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="30">
        <el-form :model="queryinfo" ref="queryinfoRef">
          <el-col :span="6.5">
            <el-form-item prop="name">
              <div style="display: inline-block" class="divSpan">
                流程名称：
              </div>
              <div style="display: inline-block">
                <el-input
                  placeholder="请输入搜索的名称"
                  v-model.trim="queryinfo.name"
                  clearable
                  @clear="getList"
                >
                  <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="seachChange"
                  ></el-button>
                </el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-form>

        <el-col :span="2">
          <el-button type="primary" icon="el-icon-circle-plus" @click="AddModel"
            >新增审批流程</el-button
          >
        </el-col>
      </el-row>

      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="ApprovalModelList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          :cell-style="{
            textAlign: 'center',
          }"
          tooltip-effect="dark"
        >
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="type"
            label="流程类型"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="流程名称"
            min-width="150px"
          ></el-table-column>
          <!-- <el-table-column label="状态" min-width="100px" prop="status">
          </el-table-column> -->
          <el-table-column
            prop="note"
            label="描述"
            min-width="200px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            min-width="150px"
          ></el-table-column>
          <el-table-column label="操作" min-width="150px">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="ModelSet(scope.row)"
                >设置</el-button
              >
              <!-- <el-button
                type="danger"
                size="mini"
                style="margin-left: 10px"
                @click="Forbidden(scope.row)"
                >禁用
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import { getFlowTypePage } from '@/api/cloudmarket/apporoval'

export default {
  data () {
    return {
      queryinfo: {
        name: '',
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      ApprovalModelList: [],
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      let quiese = { name: this.queryinfo.name }
      const res = await getFlowTypePage(this.queryinfo.pageNum, this.queryinfo.pageSize, quiese)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.ApprovalModelList = res.data.data.list
      this.total = res.data.data.total
    },
    AddModel () {
      this.$router.push({ path: '/approvalmodelSet', query: { modelApproval: '1' } })
    },
    ModelSet (para) {
      window.sessionStorage.setItem('modelTypeValue', para.type)
      this.$router.push({ path: '/approvalmodelSet', query: { modelApproval: '2' } })
    },
    Forbidden (para) {
      console.log(para)
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.queryinfo.pageSize = 10
      this.getList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getList()
    },

  }
}
</script>
<style lang="less" scoped>
.divSpan {
  font-size: 16px;
  // font-weight: 600;
}
</style>